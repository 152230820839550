<template>

        <div class="table-responsive mt-3">
          <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
            <thead class="">
              <tr>
                <th width="5%" class="text-center">#</th>
                <th width="8%" class="text-center">Codigo</th>
                <th width="40%" class="text-center">Nombre</th>
                <th width="10%" class="text-center">UM</th>
                <th width="8%" class="text-center">Cantidad</th>
                <th width="10%" class="text-center">P. Unit</th>
                <th width="5%" class="text-center">P. Total</th>
                <th width="7%" class="text-center">Acc.</th>
              </tr>
            </thead>
            <tbody v-for="(item, it) in delivery_detail" :key="it">
              <tr>
                  <td class="align-middle text-center">{{ it + 1 }}</td>
                  <td class="align-middle text-left">{{ item.code }}</td>
                  <td class="align-middle text-left">
                    <input class="form-control" v-model="item.name">
                  </td>
                  <td class="align-middle text-center">{{ item.unit_measure }}</td>
                  <td class="align-middle text-center">
                    <input type="number" step="any" @change="EditDetail(it)" class="form-control text-right" v-model="item.quantity">
                  </td>
                  <td class="align-middle text-center">
                    <input type="number" step="any" @change="EditDetail(it)" class="form-control text-right" v-model="item.unit_price">
                  </td>
                  <td class="align-middle text-center">{{ item.total_price }}</td>
                  <td class="align-middle text-center">
                    <b-button  type="button" @click="ObservationDetail(it)" size="sm" variant="primary"><i class="fas fa-comments"></i></b-button>
                    <b-button  type="button" @click="DeleteDetail(it)" size="sm" variant="danger"><i class="fas fa-trash-alt"></i></b-button>  
                    
                  </td>
              </tr>
              <tr v-if="item.observation.length > 0">
                <td colspan="9">
                    <span v-for="(item1, it1) in item.observation" :key="it1">{{ item1 + ', '}}</span>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

</template>
<style scoped>
.table-responsive{
  min-height: 15px !important;
}
</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from '@/assets/js/EventBus';

export default {
  name: "InputDetail",
  data() {
    return {
    
    };
  },
  mounted() {

  
  },
  methods: {
    EditDetail,
    DeleteDetail,
    ObservationDetail,
    ...mapActions('Delivery',['mLoadEditDeliveryDetail']),
    ...mapActions('Delivery',['mLoadDeleteDeliveryDetail']),
    
  },
  computed: {
      ...mapState('Delivery',['delivery_detail']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ObservationDetail(index) {
  let data = {
    index : index,
    role : 2,
  };
  EventBus.$emit('ModalLetterObservationShow',data);
}

function EditDetail(index) {
  let name = this.delivery_detail[index].name;
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha modificado el producto ' + name, type: 'warn'})
  this.mLoadEditDeliveryDetail(index)
}

function DeleteDetail(index) {
  let name = this.delivery_detail[index].name;
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha eliminado el producto ' + name, type: 'warn'})
  this.mLoadDeleteDeliveryDetail(index)

}
</script>
